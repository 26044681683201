import { Link } from "react-scroll";

import "./style.scss";
import photoTop from "../../../img/alfabank/alfabank-image-top-cards.png";
import generalPatner from '../../../img/footer/general_partner_visa.png';

const TopAlfa = () => {
    return (
        <div className="alfabank-top-section">
            <div className="alfabank-top-section__container">
                <div className="alfabank-top-section__main">
                    <div className="alfabank-top-section__content">
                        <h1 className="alfabank-top-section__title">
                            Бонусы для держателей карт{" "}
                            {/* <div className="alfabank-top-section__logo">
                                <img src={generalPatner} alt="Альфа Банк" className="alfabank-top-section__logo_img" />
                            </div> */}
                            Visa&nbsp;Альфа Банка
                        </h1>
                        <Link
                            className="alfabank-top-section__button"
                            to="section-navigation"
                            smooth={true}
                            offset={-80}>
                            Хочу!
                        </Link>
                    </div>
                    <div className="alfabank-top-section__image">
                        <img
                            src={photoTop}
                            alt="Alfabank Бонусы"
                            className="alfabank-top-section__image__img"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopAlfa;
