import './style.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const BottomAlfa = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <div className="footer-alfabank" data-aos="zoom-in" id='get'>
            <div className="footer-alfabank__container">
                <div className="footer-alfabank__wrapper">
                    <span className="footer-alfabank__text">Не упусти шанс, оформляй стильные карты Visa от Альфа Банка  и пользуйся преимуществами на Viva&nbsp;Braslav&nbsp;2024.</span>
                    <a href="https://www.alfabank.by/viva-braslav/?utm_source=vivabraslav&utm_medium=banner&utm_campaign=VIVA_2024" target="_blank" rel="noreferrer" className="footer-alfabank__button">Оформить</a>
                </div>
            </div>
        </div>
    );
}

export default BottomAlfa;