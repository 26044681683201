type ItemType = {
    title: string,
    description: string,
    time: string
}

const Item = ({title, description, time}:ItemType) => {
    return(
        <div className="lineup_item_details_item">
            <span className="lineup_item_details_time">{time}</span>
            <div className="lineup_item_details_info">
                <span className="lineup_item_details_text">{title}</span>
                {description && (<span className="lineup_item_details_descr">{description}</span>)}
            </div>
        </div>
    );
}

export default Item;