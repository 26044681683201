import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import PageTitle from "../../Components/pageTitle";

import calendarIcon from "../../img/program/calendar.svg";
import locationIcon from "../../img/program/location.svg";
import { useGetProgramQuery } from "../../redux/api";
import CardMini from "./cardMini";
import SportItem from "./sportItem";

import Lottie from "lottie-react";
import bell from "./bell.json";

import AOS from "aos";

import "./style.scss";
import ArtistCard from "./artistCard";

import loaderLogo from "../../img/loader-logo.svg";

import closeArrow from "../../img/program/arrow-modal.svg";
import { closeModal } from "../../redux/slices/programSlice";

import FormModal from "../applications/FormModal";
import { useLocation } from "react-router-dom";

const Program = () => {
    const { data, isLoading, error } = useGetProgramQuery();
    const { program, isOpenModal, selectedArtist } = useAppSelector(
        (state: RootState) => state.programStore
    );

    const { isOpenModal: isOpenModalApplication } = useAppSelector(
        (state: RootState) => state.applicationsStore
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        AOS.init();
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [pathname]);

    if (data) {
        console.log(selectedArtist);

        return (
            <div
                className={
                    (isOpenModal ? "open-modal" : "") ||
                    (isOpenModalApplication ? "open-modal-appl" : "")
                }>
                <div className="program-header">
                    <div className="program-header_container">
                        <div className="program-header_wrapper">
                            <h1 className="program-header_title">
                                Viva Braslav 2024
                            </h1>
                            <div className="program-header_info">
                                <div className="program-header_info_item">
                                    <div className="program-header_info_item_icon">
                                        <img
                                            src={calendarIcon}
                                            alt="Calendar"
                                        />
                                    </div>
                                    <div className="program-header_info_item_rows">
                                        <span className="program-header_info_item_row">
                                            с 17:00 26 июля 2024 г. до 03:00 27
                                            июля 2024 г.
                                        </span>
                                        <span className="program-header_info_item_row">
                                            с 14:00 27 июля 2024 г. до 04:00 28
                                            июля 2024 г.
                                        </span>
                                    </div>
                                </div>
                                <div className="program-header_info_item">
                                    <div className="program-header_info_item_icon">
                                        <img
                                            src={locationIcon}
                                            alt="Location"
                                        />
                                    </div>
                                    <div className="program-header_info_item_rows">
                                        <span className="program-header_info_item_row">
                                            Браслав, район городского пляжа
                                            озера Дривяты
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="program-sport">
                    <div className="program-sport_container">
                        <h2
                            className="program-subtitle"
                            data-aos="zoom-out-down">
                            Спортивные соревнования
                        </h2>
                        <div className="program-sport_wrapper">
                            {program["sports"].map((item, index) => {
                                return (
                                    <SportItem
                                        id={item.id}
                                        key={index}
                                        title={item.title}
                                        description={item.description}
                                        bgPhoto={item.background}
                                        status={item.state}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="program-entertainments">
                    <div className="program-entertainments_container">
                        <h2
                            className="program-subtitle"
                            data-aos="zoom-out-down">
                            Развлечения
                        </h2>
                        <div className="program-entertainments_wrapper">
                            {program["entertainments"].map((item, index) => {
                                return (
                                    <CardMini
                                        key={index}
                                        backgroundPhoto={item.background}
                                        name={item.title}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="program-entertainments">
                    <div className="program-entertainments_container">
                        <h2
                            className="program-subtitle"
                            data-aos="zoom-out-down">
                            Зоны фуд-кортов
                        </h2>
                        <div className="program-entertainments_wrapper">
                            {program["food"].map((item, index) => {
                                return (
                                    <CardMini
                                        key={index}
                                        backgroundPhoto={item.background}
                                        name={item.title}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="program-entertainments">
                    <div className="program-artists_container">
                        <h2
                            className="program-subtitle"
                            data-aos="zoom-out-down">
                            Концертная программа
                        </h2>
                        <div className="program-artists_wrapper">
                            {program["artists"].map((item, index) => {
                                return (
                                    <ArtistCard
                                        key={index}
                                        index={index}
                                        backgroundPhoto={item.photo}
                                        name={item.title}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div
                    className="program-modal"
                    style={
                        isOpenModal ? { opacity: 1, pointerEvents: "all" } : {}
                    }>
                    <div className="program-modal_container">
                        <div className="program-modal_wrapper">
                            <div
                                className="program-modal_header"
                                onClick={() => dispatch(closeModal())}>
                                <img
                                    src={closeArrow}
                                    alt="Close arrow"
                                    className="program-modal_header_icon"
                                />
                            </div>
                            <div className="program-modal_scroll">
                                <div className="program-modal_top">
                                    {selectedArtist.type === "main" && (
                                        <>
                                            <div
                                                className="program-modal_top_avatar"
                                                style={{
                                                    backgroundImage:
                                                        'url("https://vivabraslav.by/' +
                                                        selectedArtist.photo +
                                                        '")',
                                                    backgroundSize: "cover",
                                                    backgroundPosition:
                                                        "center",
                                                }}></div>
                                            <div className="program-modal_top_info">
                                                <span className="program-modal_top_info_title">
                                                    {selectedArtist.title}
                                                </span>
                                                <span className="program-modal_top_info_short">
                                                    {
                                                        selectedArtist.short_description
                                                    }
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="program-modal_description">
                                    {selectedArtist.type === "main" && (
                                        <span className="program-modal_description_name">
                                            Описание
                                        </span>
                                    )}

                                    <div
                                        className="program-modal_description_text"
                                        dangerouslySetInnerHTML={{
                                            __html: selectedArtist.description.replace(
                                                /\n/g,
                                                "<br/>"
                                            ),
                                        }}
                                    />
                                </div>

                                <div className="program-modal_organizer">
                                    <span className="program-modal_organizer_name">
                                        Организатор
                                    </span>
                                    <span className="program-modal_organizer_text">
                                        {selectedArtist.organizer}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="program-modal_bg"
                        onClick={() => dispatch(closeModal())}></div>
                </div>

                {/* <div className="program-more">
                    <div className="program-more_wrapper">
                        <Lottie animationData={bell} loop={true} />
                    </div>
                    <div className="program-more_text">
                        <span className="program-more_title">
                            Концертная программа будет дополнена.
                        </span>
                        <span className="program-more_subtitle">
                            Не забывай заходить к нам на сайт!
                        </span>
                    </div>
                </div> */}
                <div
                    className={
                        data ? "loader-program loader-program_loaded" : ""
                    }>
                    <div className="loader-program_image">
                        <img
                            src={loaderLogo}
                            alt=""
                            className="loader-program_image_img"
                        />
                    </div>
                </div>
                <FormModal />
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="loader-program">
                <div className="loader-program_image">
                    <img
                        src={loaderLogo}
                        alt=""
                        className="loader-program_image_img"
                    />
                </div>
            </div>
        );
    }
};

export default Program;
