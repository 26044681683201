import { Link } from 'react-scroll';

import './style.scss';

const NavigationAlfa = () => {
    return(
        <div className="section-navigation" id='section-navigation'>
            <div className="section-navigation__container">
                <div className="section-navigation__wrapper">
                    <div className="section-navigation__title">
                        <h3 className="section-navigation__title_text">Привилегии на фестивале</h3>
                    </div>
                    <div className="section-navigation__buttons">
                        <Link className="section-navigation__buttons__item" to="how-to-get" smooth={true} offset={-450}>
                            Как получить
                        </Link>
                        <Link className="section-navigation__buttons__item" to="important-information" smooth={true} offset={-400}>
                            Важная информация
                        </Link>
                        <a href="https://www.alfabank.by/viva-braslav/?utm_source=vivabraslav&utm_medium=banner&utm_campaign=VIVA_2024" target="_blank"  rel="noreferrer" className="section-navigation__buttons__item section-navigation__buttons__primary">Оформить</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavigationAlfa;