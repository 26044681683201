import { useAppDispatch } from "../../../app/hooks";
import { openModal } from "../../../redux/slices/programSlice";

import AOS from "aos";
import React, { useEffect } from "react";

import "./style.scss";

const ArtistCard = ({ index, name, backgroundPhoto }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div
            className="program-artists_item"
            // data-aos="flip-up"
            onClick={() => dispatch(openModal(index))}>
            <div className="program-artists_item_background_blur"></div>
            <div
                className="program-artists_item_background_img"
                style={{
                    background:
                        'url("https://vivabraslav.by/' +
                        backgroundPhoto +
                        '")',
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}></div>
            <div className="program-artists_item_name">
                <span className="program-artists_item_name_text">{name}</span>
                <div className="program-artists_item_name_arrow"></div>
            </div>
            <div className="program-artists_item_background_loader"></div>
        </div>
    );
};

export default ArtistCard;
