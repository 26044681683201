import './style.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const InstructionBlock = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <div className="instruction" data-aos="zoom-in" id='how-to-get'> 
            <div className="instruction__container">
                <h1 className="instruction__title">
                    Как получить преимущества?
                </h1>
                <div className="instruction__wrapper">
                    <div className="instruction__item" data-aos="zoom-in">
                        <div className="instruction__item__count">
                            <span className="instruction__item__count__number">1</span>
                        </div>
                        <div className="instruction__item__info" data-aos="fade-up">
                            <h3 className="instruction__item__info__title">Шаг первый</h3>
                            <span className="instruction__item__info__text">Оповестите работника фестиваля о наличии карты.</span>
                        </div>
                    </div>
                    <div className="instruction__item" data-aos="zoom-in">
                        <div className="instruction__item__count">
                            <span className="instruction__item__count__number">2</span>
                        </div>
                        <div className="instruction__item__info" data-aos="fade-up">
                            <h3 className="instruction__item__info__title">Шаг второй</h3>
                            <span className="instruction__item__info__text">Предъявите карту физически, в Pay-cервисах либо в приложении INSNC.</span>
                        </div>
                    </div>
                    <div className="instruction__item" data-aos="zoom-in">
                        <div className="instruction__item__count">
                            <span className="instruction__item__count__number">3</span>
                        </div>
                        <div className="instruction__item__info" data-aos="fade-up">
                            <h3 className="instruction__item__info__title">Шаг третий</h3>
                            <span className="instruction__item__info__text">Пользуйтесь преимуществами по картам Visa от Альфа Банка.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstructionBlock;