import './style.scss';
import cardSimple from '../../../img/alfabank/card-simple.png';
import cardPremium from '../../../img/alfabank/card-premium.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/applicationsSlice';

const AdvantagesBlocks = () => {
    useEffect(() => {
        if (window.innerWidth < 768) {
          AOS.init();
        }
      }, []);
      const dispatch = useAppDispatch();
    return(
        <div className="advantages">
            <div className="advantages__container">
                <div className="advantages__wrapper">
                    <div className="advantages__item" data-aos="zoom-in">
                        <h3 className="advantages__item__title">Для всех держателей карт Visa от Альфа&nbsp;Банка:</h3>
                        <ul className="advantages__item__list">
                            <li className="advantages__item__list__item">Cпец. цена на билет категории “Стандарт&nbsp;2&nbsp;дня” (только при покупке онлайн);</li>
                            <li className="advantages__item__list__item">Cпец. цена на услугу “Авто&nbsp;garage&nbsp;4&nbsp;дня” (только при покупке онлайн);</li>
                            <li className="advantages__item__list__item">Отдельный вход на фестиваль;</li>
                            <li className="advantages__item__list__item">Скидка 10% на всех фуд-кортах фестиваля.</li>
                            <li className="advantages__item__list__item">Скидка 10% на коллекцию фестивального мерча VIVA ALFA VISA.</li>
                        </ul>
                        <div className="advantages__item__photo">
                            <img src={cardSimple} alt="Alfabank Viva Braslav" className="advantages__item__photo__img" />
                        </div>
                    </div>
                    <div className="advantages__item" data-aos="zoom-in">
                        <h3 className="advantages__item__title">Для держателей премиальных карт Platinum, Signature и Infinite Visa от Альфа Банка:</h3>
                        <ul className="advantages__item__list">
                            <li className="advantages__item__list__item">Бесплатный единоразовый полёт на аэростате.</li>
                            
                        </ul>
                        {/* <div className="advantages__item_btn-applic"  onClick={() => dispatch(openModal(9))}>
                            <span className="advantages__item_btn-applic_text">Подать заявку</span>
                        </div> */}
                        <div className="advantages__item__photo">
                            <img src={cardPremium} alt="Alfabank Viva Braslav" className="advantages__item__photo__img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvantagesBlocks;