import PageTitle from "../../Components/pageTitle";
import Item from "./item";
import "./style.scss";

const Lineup = () => {
    return (
        <>
            <PageTitle title="Viva Braslav 2024" subtitle="Расписание" />
            <div className="lineup">
                <div className="lineup_container">
                    <div className="lineup_wrapper">
                        <div className="lineup_day">
                            <h1 className="lineup_day_title">26 июля</h1>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Main stage
                                        </span>
                                    </div>
                                </summary>
                                <div className="lineup_item_details_content">
                                    <Item
                                        title="DJ SET | Открытие"
                                        description=""
                                        time="19:00"
                                    />
                                    <Item
                                        title="Олег Майами"
                                        description=""
                                        time="19:40"
                                    />
                                    <Item
                                        title="Леша Свик"
                                        description=""
                                        time="20:20"
                                    />
                                    <Item
                                        title="Niletto"
                                        description=""
                                        time="21:10"
                                    />
                                    <Item
                                        title="МакSим"
                                        description=""
                                        time="22:30"
                                    />
                                    <Item
                                        title="Nkeeei, UNIQE, Artem Shilovets"
                                        description=""
                                        time="23:35"
                                    />
                                    <Item
                                        title="KAKA"
                                        description=""
                                        time="00:10"
                                    />
                                     <Item
                                        title="ASPYER"
                                        description=""
                                        time="01:10"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Coliseum tuborg stage
                                        </span>
                                    </div>
                                </summary>
                                <div className="lineup_item_details_content">
                                    <Item
                                        title="DJ maleek"
                                        description=""
                                        time="17:10"
                                    />
                                    <Item
                                        title="Saint Wenz"
                                        description=""
                                        time="18:35"
                                    />
                                    <Item
                                        title="Keendy"
                                        description=""
                                        time="19:15"
                                    />
                                    <Item
                                        title="BLESS"
                                        description=""
                                        time="20:00"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Frudoza stage
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Dance Up"
                                        description=""
                                        time="18:05"
                                    />
                                    <Item
                                        title="RANDOM"
                                        description=""
                                        time="20:00"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Сцена Альфа Банк | Visa
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Активности"
                                        description=""
                                        time="18:00"
                                    />
                                    <Item
                                        title="DJ LERMO & YURA DRUMMER"
                                        description=""
                                        time="22:30"
                                    />
                                    <Item
                                        title="DJ Igos"
                                        description=""
                                        time="00:30"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Пляж Belbet
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Активности"
                                        description="РЕГИСТРАЦИЯ НА Fast Marriage, КОНКУРСНАЯ ПРОГРАММА, DJ SET - ВЕДУЩИЙ АРКАДЬ ЛОГВИН"
                                        time="17:00"
                                    />
                                    <Item
                                        title="ЦЕРЕМОНИЯ Fast Marriage"
                                        description=""
                                        time="21:00"
                                    />
                                    <Item
                                        title="Dj Madbelive"
                                        description="Пенная вечеринка"
                                        time="00:00"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Сцена Яндекс Плюса
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Активности"
                                        description=""
                                        time="18:00"
                                    />
                                    <Item
                                        title="DJ LERMO & YURA DRUMMER"
                                        description=""
                                        time="22:30"
                                    />
                                    <Item
                                        title="DJ Igos"
                                        description=""
                                        time="00:30"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Спортивные мероприятия
                                        </span>
                                    </div>
                                </summary>
                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Мини-гольф"
                                        description=""
                                        time="17:00"
                                    />
                                    <Item
                                        title="Стритбол"
                                        description=""
                                        time="18:00"
                                    />
                                    <Item
                                        title="Пляжный волейбол"
                                        description=""
                                        time="18:30"
                                    />
                                </div>
                            </details>
                        </div>
                        <div className="lineup_day">
                            <h1 className="lineup_day_title">27 июля</h1>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Main stage
                                        </span>
                                    </div>
                                </summary>
                                <div className="lineup_item_details_content">
                                    <Item
                                        title="INOMARKI"
                                        description=""
                                        time="19:00"
                                    />
                                    <Item
                                        title="Kristina Si"
                                        description=""
                                        time="19:40"
                                    />
                                    <Item
                                        title="Xolidayboy"
                                        description=""
                                        time="20:40"
                                    />
                                    <Item
                                        title="Jakone & A.V.G"
                                        description=""
                                        time="21:45"
                                    />
                                    <Item
                                        title="Клава Кока"
                                        description=""
                                        time="22:55"
                                    />
                                    <Item
                                        title="ARYUE"
                                        description=""
                                        time="00:00"
                                    />
                                    <Item
                                        title="KIIDA"
                                        description=""
                                        time="01:00"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Coliseum tuborg stage
                                        </span>
                                    </div>
                                </summary>
                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Йога"
                                        description=""
                                        time="14:30"
                                    />
                                    <Item
                                        title="Незабудки"
                                        description=""
                                        time="15:40"
                                    />
                                    <Item
                                        title="AGAYAN"
                                        description=""
                                        time="16:20"
                                    />
                                    <Item
                                        title="NRCO"
                                        description=""
                                        time="17:00"
                                    />
                                    <Item
                                        title="Йога"
                                        description=""
                                        time="17:40"
                                    />
                                    <Item
                                        title="Dance Up"
                                        description=""
                                        time="18:50"
                                    />
                                    <Item
                                        title="FU-JI"
                                        description=""
                                        time="19:30"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Frudoza stage
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Муз.квиз На Бис"
                                        description=""
                                        time="14:20"
                                    />
                                    <Item
                                        title="Miss Viva Braslav"
                                        description=""
                                        time="16:00"
                                    />
                                    <Item
                                        title="Импровизация"
                                        description=""
                                        time="18:20"
                                    />
                                    <Item
                                        title="Bless Retro"
                                        description=""
                                        time="20:30"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Сцена Альфа Банк | Visa
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Активности"
                                        description=""
                                        time="14:30"
                                    />
                                    <Item
                                        title="DJ Maller"
                                        description=""
                                        time="21:30"
                                    />
                                    <Item
                                        title="DJ INCLÁME"
                                        description=""
                                        time="23:30"
                                    />
                                    <Item
                                        title="DJ ESMANUR"
                                        description=""
                                        time="01:30"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Пляж Belbet
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Активности"
                                        description="РЕГИСТРАЦИЯ НА Fast Marriage, КОНКУРСНАЯ ПРОГРАММА, DJ SET - ВЕДУЩИЙ АРКАДЬ ЛОГВИН"
                                        time="14:00"
                                    />
                                    <Item
                                        title="ЦЕРЕМОНИЯ Fast Marriage"
                                        description=""
                                        time="20:30"
                                    />
                                    <Item
                                        title="Dj Lady Marris"
                                        description="Пенная вечеринка"
                                        time="00:00"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Сцена Яндекс Плюса
                                        </span>
                                    </div>
                                </summary>

                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Активности"
                                        description=""
                                        time="14:00"
                                    />
                                    <Item
                                        title="DJ Vladlen"
                                        description=""
                                        time="14:00"
                                    />
                                    <Item
                                        title="DJ Lady Marris"
                                        description=""
                                        time="16:00"
                                    />
                                    <Item
                                        title="DJ Bagach"
                                        description=""
                                        time="19:00"
                                    />
                                    <Item
                                        title="DJ Jeneva"
                                        description=""
                                        time="00:00"
                                    />
                                </div>
                            </details>
                            <details className="lineup_item_details" open>
                                <summary className="lineup_item_summary">
                                    <div className="lineup_item_summary_wrapper">
                                        <span className="lineup_item_summary_title">
                                            Спортивные мероприятия
                                        </span>
                                    </div>
                                </summary>
                                <div className="lineup_item_details_content">
                                    <Item
                                        title="Пляжный волейбол"
                                        description=""
                                        time="14:00"
                                    />
                                    <Item
                                        title="Семейный забег 1 км"
                                        description=""
                                        time="14:00"
                                    />
                                    <Item
                                        title="Забег 2 км"
                                        description=""
                                        time="14:30"
                                    />
                                    <Item
                                        title="Забег 4 км"
                                        description=""
                                        time="15:00"
                                    />
                                    <Item
                                        title="Стритбол"
                                        description=""
                                        time="15:00"
                                    />
                                    <Item
                                        title="Мини-гольф"
                                        description=""
                                        time="15:30"
                                    />
                                </div>
                            </details>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Lineup;
