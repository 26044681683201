

import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/applicationsSlice';

import "./style.scss";

const SportItem = ({ id, title, description, bgPhoto, status }) => {

    const dispatch = useAppDispatch();

    const returnBtn = () => {
        switch (status) {
            case "soon":
                return <div className="program-sport_item_btn">Скоро</div>;
            case "open":
                return (
                    <div className="program-sport_item_btn-active" onClick={() => dispatch(openModal(id))}>Подать заявку</div>
                );
            default:
                return (
                    <div className="program-sport_item_btn">Приём закрыт</div>
                );
        }
    };

    return (
        <div className="program-sport_item">
            <div className="program-sport_item_background">
                <div
                    className="program-sport_item_bg"
                    style={{
                        background:
                            'url("https://vivabraslav.by/' + bgPhoto + '")',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}></div>
                <div className="program-sport_item_background_loader"></div>
            </div>
            <div className="program-sport_item_info">
                <span className="program-sport_item_title">{title}</span>
                <span className="program-sport_item_description">
                    {description}
                </span>
                {returnBtn()}
            </div>
        </div>
    );
};

export default SportItem;
