import Lottie from "lottie-react";
import React, { useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PageTitle from "../../Components/pageTitle";
import './style.scss';
import zoomIn from "../howToGet/zoom.json";

import festmap from '../../img/maps/fest2024.jpeg';
import globalmap from '../../img/maps/global2024.jpeg';
import { useLocation } from "react-router-dom";

const Maps = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);

    return (
        <>
            <PageTitle 
                title=""
                subtitle=""
            />
            <section className="map">
                <div className="map_container">
                    
                    <div className="map_wrapper">
                        <h1 className="map_komunikat">Карта территории</h1>
                        <div className="map_border">
                            <TransformWrapper>
                                <TransformComponent>
                                    <div className="modal-route">
                                        <Lottie
                                            animationData={zoomIn}
                                            loop={false}
                                            className="zoom-animation"
                                        />
                                        <img src={globalmap} alt="test" />
                                    </div>
                                </TransformComponent>
                            </TransformWrapper>
                        </div>
                    </div>
                    <div className="map_wrapper">
                        <h1 className="map_komunikat">Карта фестиваля</h1>
                        <div className="map_border">
                            <TransformWrapper>
                                <TransformComponent>
                                    <div className="modal-route">
                                        <Lottie
                                            animationData={zoomIn}
                                            loop={false}
                                            className="zoom-animation"
                                        />
                                        <img src={festmap} alt="test" />
                                    </div>
                                </TransformComponent>
                            </TransformWrapper>
                        </div>
                    </div>
                    {/* <div className="map_wrapper">
                        <h1 className="map_komunikat">Совсем скоро здесь появится карта фестиваля Viva&nbsp;Braslav&nbsp;2024</h1>
                        <div className="map_border">
                            <img src={festmap} alt="Festival map" className="map_image" />
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default Maps;
