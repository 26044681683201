import './style.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const ImportantBlock = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <div className="important" data-aos="zoom-in" id='important-information'>
            <div className="important__container">
                <div className="important__wrapper">
                    <h1 className="important__title">Важная информация</h1>
                    <ul className="important__list">
                        <li className="important__list__item" data-aos="fade-right">Все преимущества действуют исключительно при предъявлении карты.</li>
                        <li className="important__list__item" data-aos="fade-right">Преимущества предоставляются только владельцу карты.</li>
                        <li className="important__list__item" data-aos="fade-right">Преимущества не предоставляются при предъявлении карты посредством фото на телефонном носителе.</li>
                    </ul>
                </div>
            </div>
        </div>
    );

}

export default ImportantBlock;