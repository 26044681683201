import './style.scss';

import AOS from "aos";
import React, { useEffect } from "react";
const CardMini = ({ backgroundPhoto, name }) => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="program-entertainments_item" data-aos="fade-up">
            <div className="program-entertainments_item_background">
                <div
                    className="program-entertainments_item_background_img"
                    style={{
                        background:
                            'url("https://vivabraslav.by/' +
                            backgroundPhoto +
                            '")',
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}></div>
                <div className="program-entertainments_item_background_loader"></div>
            </div>
            <span className="program-entertainments_item_name">{name}</span>
        </div>
    );
};

export default CardMini;
